var generic = generic || {};

(function($) {

if (!!window.localStorage) {
  var origSetItem = Storage.prototype.setItem;
  Object.defineProperty(Storage.prototype, 'setItem', {
    value: function() {
      var args = $.makeArray(arguments);
      if (!!args[0] && !!args[1]) {
        $(document).trigger('storage', [ args[0], args[1] ]);
      }
      origSetItem.apply(this, arguments);
    }
  });
}

})(jQuery);

/**
 * Usage:
 *
 * var data = generic.data.get('SomeName');
 *
 * data.set('SomeName', { key: value }, {
 *   type: 'local',
 *   expires: function() {
 *     var date = new Date();
 *     date.setDate(date.getDate() + 1);  // today + 1 day
 *     return date;
 *   }
 * });
 */
generic.data = (function() {
  var data = {
    future: 9999999999,

    /**
     * Utility function
     */
    isFunction: function(val) {
      return !!(val && val.constructor && val.call && val.apply);
    },

    /**
     * Utility function
     */
    isEmpty: function(val) {
      var undef;
      var ev = [undef, null, false, 0, '', '0'];
      for (var i = 0, len = ev.length; i < len; i++) {
        if (val === ev[i]) {
          return true;
        }
      }
      return false;
    },

    /**
     * Utility function to detect an invalid storage type
     */
    isValidStoreType: function(type) {
      return (!this.isEmpty(type) && ['local', 'session', 'cookie'].indexOf(type) != -1);
    },

    /**
     * Cookie storage object. It's a middle-man to the cookies.
     */
    cookieStorage: {
      getItem: function(name) {
        var c_start, c_end;
        if (document.cookie.length > 0) {
          c_start = document.cookie.indexOf(name + '=');
          if (c_start !== -1) {
            c_start = c_start + name.length + 1;
            c_end = document.cookie.indexOf(';', c_start);
            if (c_end === -1) {
              c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end)).replace(/^"(.*)"$/, '$1');
          }
        }
        return '';
      },

      setItem: function(name, value) {
        if (typeof value !== 'string') {
          value = JSON.parse(value);
        }
        var expires = value.expires;
        var date = new Date();
        date.setTime(expires * 1000);  // data is passed as epoch seconds (not milli), so we multiply by 1000
        expires = ';expires=' + date.toGMTString();
        document.cookie = name + '=' + JSON.stringify(value).replace(/^"(.*)"$/, '$1') + expires + ';path=/';
      },

      removeItem: function(name) {
        this.setItem(name, '', -1);
      }
    },

    /**
     * Utility function to get the storage object from the type
     */
    getStore: function(key) {
      var map = {
        cookie   : this.cookieStorage,
        local    : window.localStorage,
        session  : window.sessionStorage
      };

      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      key = isIE11 ? 'cookie' : key;

      return map[key] || false;
    },

    /**
     * Getter
     */
    get: function(name, type) {
      name = !this.isEmpty(name) ? name : false;
      type = this.isValidStoreType(type) ? type: 'local';

      var store = this.getStore(type);

      if (!name || !store) {
        return '';
      }

      var data = store.getItem(name);

      if (!data) {
        return '';
      }

      if (typeof data !== 'string') {
        data = JSON.parse(data);
      }

      // Removing the expiration feature because the react components won't be calling these helper methods
      // expires = (type != 'cookie' && !!data.expires) ? data.expires : this.future;
      // return (now < expires && !!data.data) ? data.data : '';
      return data;
    },

    /**
     * Setter
     */
    set: function(name, value, options) {
      options = !this.isEmpty(options) ? options : {};
      var type = (!!options.type && this.isValidStoreType(options.type)) ? options.type : 'local';
      var store = this.getStore(type);

      if (this.isEmpty(name) || !value || !store) {
        return false;
      }

      // Removing the expiration feature because the react components won't be calling these helper methods
      // var expires = options.expires || this.future;
      // if (this.isFunction(expires)) {
      //   expires = Math.round(expires().getTime() / 1000.0);
      // }

      // store.setItem(name, JSON.stringify({
      //   expires : expires,
      //   data    : value
      // }));

      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      store.setItem(name, value);
    },

    /**
     * Remover
     */
    remove: function(name, type) {
      name = !this.isEmpty(name) ? name : false;
      type = this.isValidStoreType(type) ? type: 'local';

      var store = this.getStore(type);
      store.removeItem(name);
    }
  };

  return data;
})();

/**
 * (function($) {
 *
 * $(function() {
 *    generic.data.set('Cart', {'greg': 'local'}, {
 *      type: 'cookie',
 *      expires: function() {
 *        var date = new Date();
 *        date.setDate(date.getDate() + 1);  // today + 1 day
 *        return date;
 *      }
 *    });
 *    var d = generic.data.get('Cart', 'local');
 * 
 *    console.log(' ---- storage demo ---- ');
 *    console.log(d);
 * });
 *
 * })(jQuery);
 **/
